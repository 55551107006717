var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e(), _c('div', {
          staticClass: "cyui-driections-navbar"
        }, [_c('div', {
          staticClass: "system-title"
        }, [_c('van-icon', {
          attrs: {
            "name": "label",
            "size": "30"
          }
        }), _c('span', [_vm._v("入门必读")])], 1), _c('div', {
          staticClass: "system-content"
        }, _vm._l(_vm.shouceList, function (item) {
          return _c('div', {
            key: item.id,
            staticClass: "content"
          }, [_c('a', {
            on: {
              "click": function ($event) {
                return _vm.handelClick_open(item);
              }
            }
          }, [_vm._v(_vm._s(item.question))])]);
        }), 0)])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };